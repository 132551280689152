import React from "react";
import { menuItems } from "./constant";
import classNames from "classnames";
import styles from "./index.module.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "./slice/layoutSlice";

const Sidebar = () => {
  const location = useLocation();
  const pathName = location.pathname.split("/");
  const result = pathName.slice(0, 2).join("/");
  const { isOpen } = useSelector(state => state.layout);
  const dispatch = useDispatch();

  const handelChange = () => {
    dispatch(toggleSidebar());
  };

  return (
    <div
      className={classNames(
        isOpen ? styles.activeSidebar : styles.deActiveSidebar,
        "bg-white border shadow",
        styles.sidebar,
      )}
    >
      <div
        className={classNames(
          styles.sidebarContain,
          "d-flex flex-column justify-content-center align-items-center",
        )}
      >
        <h3 className={classNames(styles.h3Tag)}>
          POINT <span className={classNames(styles.spanTag)}>LEAGUE</span>
        </h3>
        <hr className={classNames(styles.hrTag)} />
        <div className={classNames(styles.mainDivMenu)}>
          {menuItems?.map((items, index) => {
            return (
              <Link to={`/${items.routes}`} key={index} onClick={handelChange}>
                <div
                  className={classNames(
                    `/${items.routes}` === result
                      ? styles.selectedMenuItems
                      : styles.MenuItems,
                    "d-flex justify-content-start align-items-center gap-2",
                  )}
                >
                  <span>
                    {`/${items.routes}` === result
                      ? items.selectedIcon
                      : items.menuIcon}
                  </span>
                  <p
                    className={classNames(
                      `/${items.routes}` === result
                        ? styles.menuName
                        : styles.nonHoveMenuName,
                      "mb-0",
                    )}
                  >
                    {items.menuName}
                  </p>
                  <span
                    className={classNames(
                      `/${items.routes}` === result
                        ? styles.selectedVertical
                        : styles.vertical,
                    )}
                  ></span>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
