//import { companyLogo } from "constants/images";
//import { FiLoader } from "react-icons/fi";
import React from "react";
//import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { Rings } from "react-loader-spinner";

const PageLoader = () => {
  return (
    <div className="page-Loader">
      <Rings
        height="80"
        width="80"
        color="#1b254b"
        radius="6"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="rings-loading"
      />
    </div>
  );
};

export default PageLoader;

// {/* <div className="container-scroller"> */}
// <div className="container-fluid page-body-wrapper full-page-wrapper">
//   <div className="main-panel">
//     <div className="w-100 h-100 d-flex justify-content-center align-items-center">
//       <div>
//         {/* <img src={companyLogo} alt="Logo" style={{ height: "100px" }} /> */}
//         {/* <FiLoader size={25} /> */}

//         <div className="mt-4 d-flex justify-content-center align-items-center">
//           {/* <HiOutlineDotsHorizontal /> */}
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
