import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "services/cookies";
import { LOGIN } from "../../../constants/url";
import { axiosPointLeagueAdmin } from "services/api";
import { toast } from "react-toastify";

const token = Cookies.get("token");

const initialState = {
  //isAuth: true,
  isAuth: !!token,
  user: null,
  loading: false,
  token: !!token ? token : "",
  error: null,
};

export const LoginThunk = createAsyncThunk(
  `authentication/LoginThunk`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.post(LOGIN, payload);
      toast.success(response?.data?.message);
      return response.data;
    } catch (err) {
      toast.error("Username or password is invalid.");
      return thunkAPI.rejectWithValue(
        err?.response?.data?.non_field_errors?.at(0),
      );
    }
  },
);

export const LoginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    logout: state => {
      state.isAuth = false;
      state.user = null;
      state.token = "";
    },
  },
  extraReducers: builder => {
    builder.addCase(LoginThunk.pending, state => {
      state.loading = true;
      state.isAuth = false;
    });
    builder.addCase(LoginThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuth = true;
      state.user = action.meta.arg.email;
      state.token = action.payload.data.token;
    });
    builder.addCase(LoginThunk.rejected, (state, { payload }) => {
      state.loading = false;
      state.isAuth = false;
    });
  },
});

export const { logout } = LoginSlice.actions;

export default LoginSlice.reducer;

// I have full Size left sidebar & next i have header bar & that header bar bottom I have my contain how to fully responsive this code in react js
