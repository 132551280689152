import { AiFillHome } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { MdSportsSoccer } from "react-icons/md";
import { RiPagesLine } from "react-icons/ri";
import routesConstants from "routes/routesConstants";

export const menuItems = [
  {
    menuName: "Main Dashboard",
    selectedIcon: <AiFillHome size={18} color="#422afb" />,
    menuIcon: <AiFillHome size={18} color="grey" />,
    routes: routesConstants.HOME_PAGE,
  },
  {
    menuName: "Player Master",
    selectedIcon: <MdSportsSoccer size={18} color="#422afb" />,
    menuIcon: <MdSportsSoccer size={18} color="grey" />,
    routes: routesConstants.PLAYER_MASTER_PAGE,
  },
  {
    menuName: "User Master",
    selectedIcon: <BiUserCircle size={18} color="#422afb" />,
    menuIcon: <BiUserCircle size={18} color="grey" />,
    routes: routesConstants.USER_MASTER_PAGE,
  },
  {
    menuName: "System Page",
    selectedIcon: <RiPagesLine size={18} color="#422afb" />,
    menuIcon: <RiPagesLine size={18} color="grey" />,
    routes: routesConstants.SYSTEM_PAGES,
  },
];
