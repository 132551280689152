import lazy from "utils/lazy";

export const LoginPage = lazy(
  () =>
    import(/* webpackChunkName: "LoginPage" */ "../modules/login/pages/index"),
);

export const HomePage = lazy(
  () =>
    import(/* webpackChunkName: "HomePage" */ "../modules/home/pages/index"),
);

export const PlayerMasterPage = lazy(
  () =>
    import(
      /* webpackChunkName: "playerMasterPage" */ "../modules/playermaster/pages/index"
    ),
);

export const UserMasterPage = lazy(
  () =>
    import(
      /* webpackChunkName: "UserMaster" */ "../modules/usermaster/pages/index"
    ),
);

export const Playerdetail = lazy(
  () =>
    import(
      /* webpackChunkName: "Playerdetail" */ "modules/playermaster/pages/Playerdetail"
    ),
);

export const states = lazy(
  () =>
    import(
      /* webpackChunkName: "states" */ "modules/playermaster/pages/States"
    ),
);

export const userProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "states" */ "modules/usermaster/pages/UserProfile"
    ),
);

export const userHistoric = lazy(
  () =>
    import(
      /* webpackChunkName: "states" */ "modules/usermaster/pages/UserHistoric"
    ),
);

//SystemPages

export const systemPages = lazy(
  () =>
    import(
      /* webpackChunkName: "SystemPages" */ "modules/systemPage/Pages/index"
    ),
);
