import axios from "axios";
import Cookies from "./cookies";
import store from "../store";
import { logout } from "modules/login/features/loginSlice";

const BASE_URL = "https://api.pointleague.us";
//const BASE_URL = "http://192.168.1.129:8080";

export const POINTLEAGUE_ADMIN_API_URL = `${BASE_URL}/api/v1`;

class Axios {
  constructor(baseURL) {
    this.axios = axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use(this._requestMiddleware);
    this.axios.interceptors.response.use(
      this._responseMiddleware,
      this._responseErr,
    );
  }

  _requestMiddleware = req => {
    // Send Bearer token on every request
    const token = Cookies.get("token");
    if (!!token)
      req.headers.authorization = token.startsWith("Token")
        ? token
        : "Token " + token;
    return req;
  };

  _responseMiddleware = response => {
    //  Do something on every success full response.
    // console.log('inTOKEN',response.data.data.token);
    if (response?.data?.data?.token) {
      Cookies.set("token", response.data.data.token);
    }
    return response;
  };

  _responseErr = error => {
    //if error comes than handle here
    if (error?.response?.status === 401) {
      Cookies?.clear();
      store.dispatch(logout());
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };
}

const axiosPointLeagueAdmin = new Axios(POINTLEAGUE_ADMIN_API_URL).axios;

export { axiosPointLeagueAdmin };
