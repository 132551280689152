import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import styles from "./index.module.css";
import classNames from "classnames";
const Layout = () => {
  const { isAuth } = useSelector(state => state.login);

  return isAuth ? (
    // <div className="d-flex">
    //   <Sidebar />
    //   <div className="d-flex flex-column">
    //     <Header />
    //     <Outlet />
    //   </div>
    //   {/* <Footer /> */}
    // </div>
    // <div className={classNames(styles.containerFluid)}>
    //   <div className="row">
    //     <div className={classNames("col-md-3")} >
    //       <Sidebar/>
    //     </div>
    //     <div className="col-md-9">
    //       <div className="header">
    //         <Header />
    //       </div>
    //       <div className={classNames(styles.content)}>
    //         <Outlet />
    //       </div>
    //     </div>
    //   </div>
    // </div>

    // <div className={classNames(styles.containerFluid)}>
    //   <div className="row">
    //     <div className="col-sm-3">
    //       <Sidebar />
    //     </div>
    //     <div className="col-sm-9">
    //       <div style={{ position: "relative", right: "28px" }}>
    //         <Header />
    //         <div className="">
    //           <Outlet />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className={classNames(styles.app)}>
      <div className={classNames()}>
        <Sidebar />
      </div>
      <div className={classNames(styles.content)}>
        <Header />
        <div className={classNames(styles.contentInner)}>
          <Outlet />
        </div>
      </div>
    </div>
  ) : (
    <Outlet />
  );
};

export default Layout;
