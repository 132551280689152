import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  GetNcaamPlayerDetail,
  GetNcaamPlayerscore,
  ListNcaamPlayers,
  PLAYERPROFILE,
  PlayerProfileDetailsUrl,
  PlayerScore,
  getPlayerNextGame,
} from "constants/url";
import { axiosPointLeagueAdmin } from "services/api";

const initialState = {
  playerLoading: true,
  playerScoreLoading: true,
  PlayersDetailsLoading: true,
  playerNextGameLoading: true,
  players: [],
  playerCount: 0,
  playerDetails: {},
  navigatePlayerDetails: {},
  playerDetailsPoints: [],
  playerMainStates: [],
  playerRegularSeason: [],
  playerPostSeason: [],
  currentRegularSeason: {},
  playerNextGame: {},
  PlayerNcaamScore: [],
  leagueType: "",
  hasMore: true,
};

export const FetchPlayersNBA = createAsyncThunk(
  `player/FetchPlayersNBA`,
  async (payload, thunkAPI) => {
    //console.log(";payload", payload);
    try {
      const response = await axiosPointLeagueAdmin.post(
        PLAYERPROFILE,
        payload.data,
        payload.headers,
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const FetchPlayersNcaam = createAsyncThunk(
  `player/FetchPlayersNcaam`,
  async (payload, thunkAPI) => {
    //console.log(";payload", payload);
    try {
      const response = await axiosPointLeagueAdmin.post(
        ListNcaamPlayers,
        payload.data,
        payload.headers,
      );
      //console.log("response-------->", response);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const FetchPlayersDetails = createAsyncThunk(
  `player/FetchPlayersDetails`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.post(
        PlayerProfileDetailsUrl,
        payload.data,
        payload.headers,
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const FetchNcaamPlayersDetails = createAsyncThunk(
  `player/FetchNcaamPlayersDetails`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.post(
        GetNcaamPlayerDetail,
        payload.data,
        payload.headers,
      );
      // console.log("response", response);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const FetchPlayersGetPlayerScore = createAsyncThunk(
  `player/FetchPlayersGetPlayerScore`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.post(
        PlayerScore,
        payload.data,
        payload.headers,
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const GetNcaamPlayerScoreDetails = createAsyncThunk(
  `player/GetNcaamPlayerScoreDetails`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.post(
        GetNcaamPlayerscore,
        payload.data,
        payload.headers,
      );
      // console.log("response", response);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const FetchNextTeamGame = createAsyncThunk(
  `player/FetchNextTeamGame`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.post(
        getPlayerNextGame,
        payload.data,
        payload.headers,
      );
      //console.log("🚀 ~ file: PlayerSlice.js:94 ~ response:", response);
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const playerMasterSlice = createSlice({
  name: "playerMaster",
  initialState,
  reducers: {
    setPlayersDetails: (state, action) => {
      //console.log("action", action);
      state.navigatePlayerDetails = action.payload;
    },
    setLeagueTypeSlice: (state, action) => {
      //console.log("action", action);
      state.leagueType = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(FetchPlayersNBA.pending, state => {
      state.playerLoading = true;
    });
    builder.addCase(FetchPlayersNBA.fulfilled, (state, action) => {
      // console.log(
      //   "🚀 ~ file: PlayerSlice.js:144 ~ builder.addCase ~ action:",
      //   action,
      // );
      state.playerLoading = false;
      state.playerCount = action?.payload?.data?.count;
      if (action?.payload?.data?.page_no === 1) {
        state.players = [...action?.payload?.data?.rows];
        state.hasMore = true;
        if (state.players.length === state.playerCount) {
          state.hasMore = false;
        }
      } else {
        state.players = [...state.players, ...action?.payload?.data?.rows];
        state.hasMore = true;
        if (state.players.length === state.playerCount) {
          state.hasMore = false;
        }
      }
    });
    builder.addCase(FetchPlayersNBA.rejected, (state, action) => {
      state.playerLoading = false;
    });
    builder.addCase(FetchPlayersNcaam.pending, state => {
      state.playerLoading = true;
    });
    builder.addCase(FetchPlayersNcaam.fulfilled, (state, action) => {
      // console.log("action", action);
      state.playerLoading = false;
      state.playerCount = action?.payload?.data?.countdata;
      if (action?.payload?.data?.page_no === 1) {
        state.players = [...action.payload.data.rows];
        state.hasMore = true;
        if (state.players.length === state.playerCount) {
          state.hasMore = false;
        }
      } else {
        state.players = [...state.players, ...action?.payload?.data?.rows];
        state.hasMore = true;
        if (state.players.length === state.playerCount) {
          state.hasMore = false;
        }
      }
    });
    builder.addCase(FetchPlayersNcaam.rejected, (state, action) => {
      state.playerLoading = false;
    });
    builder.addCase(FetchPlayersDetails.pending, state => {
      state.PlayersDetailsLoading = true;
    });
    builder.addCase(FetchPlayersDetails.fulfilled, (state, action) => {
      // console.log(
      //   "🚀 ~ file: PlayerSlice.js:131 ~ builder.addCase ~ action:",
      //   action
      // );
      state.PlayersDetailsLoading = false;
      state.playerDetails = action?.payload?.data?.data;
      // state.playerDetailsPoints =
      //   action?.payload?.data?.playerHeadlineStats === undefined
      //     ? []
      //     : action?.payload?.data?.playerHeadlineStats;
    });
    builder.addCase(FetchPlayersDetails.rejected, (state, action) => {
      state.PlayersDetailsLoading = false;
    });
    builder.addCase(FetchNcaamPlayersDetails.pending, state => {
      state.PlayersDetailsLoading = true;
    });
    builder.addCase(FetchNcaamPlayersDetails.fulfilled, (state, action) => {
      // console.log(
      //   "🚀 ~ file: PlayerSlice.js:131 ~ builder.addCase ~ action:",
      //   action
      // );
      state.PlayersDetailsLoading = false;
      state.playerDetails = action?.payload?.data?.data;
      // state.playerDetailsPoints =
      //   action?.payload?.data?.playerHeadlineStats === undefined
      //     ? []
      //     : action?.payload?.data?.playerHeadlineStats;
    });
    builder.addCase(FetchNcaamPlayersDetails.rejected, (state, action) => {
      state.PlayersDetailsLoading = false;
    });

    builder.addCase(FetchPlayersGetPlayerScore.pending, state => {
      state.playerScoreLoading = true;
    });
    builder.addCase(FetchPlayersGetPlayerScore.fulfilled, (state, action) => {
      // console.log(
      //   "🚀 ~ file: PlayerSlice.js:144 ~ builder.addCase ~ action:",
      //   action,
      // );

      //const currentDate = new Date();
      //const currentYear = currentDate.getFullYear() + 1;
      //const nextYear = currentYear - 1;
      // const yearFormat = `${nextYear}-${currentYear.toString().slice(-2)}`;
      const allData = action?.payload?.data?.playerScore;

      if (allData.length === 0) {
        state.currentRegularSeason = {};
        state.playerMainStates = [];
        state.playerRegularSeason = [];
        state.playerPostSeason = [];
        state.playerScoreLoading = false;
      } else {
        const careerRegularSeason = allData?.filter(
          item => item?.Stat_type === "career_Regular_Season",
        );
        const regularSeason = allData?.filter(
          item => item?.Stat_type === "Regular_Season",
        );
        const careerPostSeason = allData?.filter(
          item => item?.Stat_type === "career_Totals_PostSeason",
        );
        const postSeason = allData?.filter(
          item => item?.Stat_type === "Total_PostSeason",
        );
        // const StatsRecentRegularSeason = allData?.filter(
        //   item => item?.type === "Regular_Season" && item.seasonId === yearFormat,
        // );
        // const StatsRecentPostSeason = allData?.filter(
        //   item =>
        //     item?.type === "Total_PostSeason" && item.seasonId === yearFormat,
        // );
        // const resultMergeRegularSeason = StatsRecentRegularSeason?.reduce(
        //   (accum, current) => {
        //     Object.entries(current).forEach(([key, value]) => {
        //       accum[key] = accum[key] + value || value;
        //     });
        //     return {
        //       ...accum,
        //     };
        //   },
        //   {},
        // );
        // const resultMergePostSeason = StatsRecentPostSeason?.reduce(
        //   (accum, current) => {
        //     Object.entries(current).forEach(([key, value]) => {
        //       accum[key] = accum[key] + value || value;
        //     });
        //     return {
        //       ...accum,
        //     };
        //   },
        //   {},
        // );
        // console.log(
        //   "StatsRecentRegularSeason",
        //   Object.keys(resultMergeRegularSeason).length > 0,
        //   Object.keys(resultMergePostSeason).length > 0,
        // );
        // console.log("StatsRecentPostSeason", resultMergePostSeason);
        // console.log("resultMergeRegularSeason", resultMergeRegularSeason);

        state.currentRegularSeason = {
          pts: regularSeason[0]?.PTS,
          reb: regularSeason[0]?.REB,
          ast: regularSeason[0]?.AST,
          ftPct: regularSeason[0]?.FGprc,
        };

        state.playerMainStates = [
          regularSeason[0] ? regularSeason[0] : {},
          postSeason[0] ? postSeason[0] : {},
          ...careerRegularSeason,
        ];

        state.playerRegularSeason = [...regularSeason, ...careerRegularSeason];
        state.playerPostSeason = [...postSeason, ...careerPostSeason];
        state.playerScoreLoading = false;
      }

      //end
    });
    builder.addCase(FetchPlayersGetPlayerScore.rejected, (state, action) => {
      state.playerScoreLoading = false;
    });

    builder.addCase(GetNcaamPlayerScoreDetails.pending, state => {
      state.playerScoreLoading = true;
    });
    builder.addCase(GetNcaamPlayerScoreDetails.fulfilled, (state, action) => {
      // console.log(
      //   "🚀 ~ file: PlayerSlice.js:144 ~ builder.addCase ~ action34666645345345435:",
      //   action,
      // );
      const allData = action?.payload?.data?.playerScore;
      state.PlayerNcaamScore = [...action?.payload?.data?.playerScore];
      //end
    });
    builder.addCase(GetNcaamPlayerScoreDetails.rejected, (state, action) => {
      state.playerScoreLoading = false;
    });

    builder.addCase(FetchNextTeamGame.pending, state => {
      state.playerNextGameLoading = true;
    });
    builder.addCase(FetchNextTeamGame.fulfilled, (state, action) => {
      state.playerNextGame = { ...action?.payload?.data?.data[0] };
      state.playerNextGameLoading = false;
    });
    builder.addCase(FetchNextTeamGame.rejected, (state, action) => {
      state.playerNextGameLoading = true;
    });
  },
});

export const { setPlayersDetails, setLeagueTypeSlice } =
  playerMasterSlice.actions;
export default playerMasterSlice.reducer;
