// import { lazy } from "react";
// import { Outlet } from "react-router-dom";
// import { roles } from "constants/permissions";

// import _404 from "components/common/_404";
// import Unauthorized from "components/common/Unauthorized";
// import RequiredAuth from "components/common/RequiredAuth";
import { Outlet } from "react-router-dom";
import {
  LoginPage,
  HomePage,
  PlayerMasterPage,
  UserMasterPage,
  Playerdetail,
  states,
  userProfile,
  userHistoric,
  systemPages,
} from "./routeImports";
import routesConstants from "./routesConstants";

const routesConfig = {
  common: [],
  private: [
    {
      path: routesConstants.HOME_PAGE,
      Component: HomePage,
    },
    {
      path: routesConstants.PLAYER_MASTER_PAGE,
      Component: Outlet,
      children: [
        { index: true, Component: PlayerMasterPage },
        { path: ":playermasterid", Component: Playerdetail },
        { index: true, Component: PlayerMasterPage },
        { path: ":playermasterid/state", Component: states },
      ],
    },
    {
      path: routesConstants.USER_MASTER_PAGE,
      Component: Outlet,
      children: [
        { index: true, Component: UserMasterPage },
        { path: ":userProfileId", Component: userProfile },
        { path: ":userProfileId/:userHistoric", Component: userHistoric },
      ],
    },
    {
      path: routesConstants.SYSTEM_PAGES,
      Component: systemPages,
    },
  ],
  public: [{ path: routesConstants.LOGIN, Component: LoginPage }],
};

export default routesConfig;
