import { combineReducers } from "@reduxjs/toolkit";
import layoutSlice from "layout/slice/layoutSlice";
import homeSlice from "modules/home/features/homeSlice";

import loginSlice from "modules/login/features/loginSlice";
import playerMasterSlice from "modules/playermaster/slice/PlayerSlice";
import PolicySlice from "modules/systemPage/slice/PolicySlice";
import userSlice from "modules/usermaster/slice/userSlice";

const reducer = combineReducers({
  login: loginSlice,
  users: userSlice,
  players: playerMasterSlice,
  layout: layoutSlice,
  policy: PolicySlice,
  home: homeSlice,
});

export default reducer;
