import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ListAllPage,
  PolicyListPage,
  createAndEditPages,
  deletePolicyPage,
} from "constants/url";
import { axiosPointLeagueAdmin } from "services/api";

const initialState = {
  policyListLoading: true,
  previewPolicyDetailsLoading: true,
  listPolicy: [],
  editPolicyDetails: {},
  previewPolicyDetails: {},
};

export const CreateAndEditPolicy = createAsyncThunk(
  `policy/CreateAndEditPolicy`,
  async (payload, thunkAPI) => {
    //console.log("payload", payload);
    try {
      const response = await axiosPointLeagueAdmin.post(
        createAndEditPages,
        payload,
      );
      //console.log("🚀 ~ file: PolicySlice.js:15 ~ response:", response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);
//
export const listPolicyDetails = createAsyncThunk(
  `policy/ListAllPage`,
  async (payload, thunkAPI) => {
    //console.log("payload", payload);
    try {
      const response = await axiosPointLeagueAdmin.post(ListAllPage, payload);
      // console.log("🚀 ~ file: PolicySlice.js:15 ~ response:", response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const deletePagePolicy = createAsyncThunk(
  `policy/deletePolicyPage`,
  async (payload, thunkAPI) => {
    // console.log("payload", payload);
    try {
      const response = await axiosPointLeagueAdmin.get(
        deletePolicyPage + `/${payload.data}`,
        payload.headers,
      );
      // console.log("🚀 ~ file: PolicySlice.js:15 ~ response:", response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const PreviewPolicyList = createAsyncThunk(
  `policy/PreviewPolicyList`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.get(
        PolicyListPage + `/${payload.data}`,
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const PolicySlice = createSlice({
  name: "policy",
  initialState,
  reducers: {
    editPolicy: (state, action) => {
      state.editPolicyDetails = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(listPolicyDetails.pending, state => {
      state.policyListLoading = true;
    });
    builder.addCase(listPolicyDetails.fulfilled, (state, action) => {
      state.policyListLoading = false;
      const newArray = [];
      const data = action?.payload?.data?.Data;
      for (let index = 0; index < data.length; index++) {
        const element = { index: index + 1, ...data[index] };
        newArray.push(element);
      }
      state.listPolicy = [...newArray];
    });
    builder.addCase(listPolicyDetails.rejected, (state, { payload }) => {
      state.policyListLoading = false;
    });
    builder.addCase(PreviewPolicyList.pending, state => {
      state.previewPolicyDetailsLoading = true;
    });
    builder.addCase(PreviewPolicyList.fulfilled, (state, action) => {
      state.previewPolicyDetailsLoading = false;
      state.previewPolicyDetails = { ...action?.payload?.data?.Data };
    });
    builder.addCase(PreviewPolicyList.rejected, (state, { payload }) => {
      state.previewPolicyDetailsLoading = false;
    });
  },
});
export const { editPolicy } = PolicySlice.actions;
export default PolicySlice.reducer;
