import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteUserProfile,
  editUserProfile,
  getUser,
  getUserLeagues,
  listOfAcceptInviteUsers,
  listUserLeaguePlayerQueue,
  listUserProfile,
} from "../../../constants/url";
import { toast } from "react-toastify";
import { axiosPointLeagueAdmin } from "services/api";

const initialState = {
  userLoading: true,
  fetchUserDetailsLoading: true,
  fetchUserLeagueLoading: true,
  inviteUsersLoading: true,
  listUserLeaguePlayerLoading: true,
  editUserLoading: false,
  user: [],
  navigateUserDetail: {},
  navigateLeagueId: {},
  fetchUser: {},
  fetchUserLeague: [],
  inviteUserLeague: [],
  leagueOwnerData: {},
  listUserLeaguePlayer: [],
  userCount: 0,
};

export const FetchUsers = createAsyncThunk(
  `user/FetchUsers`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.post(
        getUser,
        payload.data,
        payload.headers,
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const FetchUserDetails = createAsyncThunk(
  `user/FetchUserDetails`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.post(
        listUserProfile + `/${payload}`,
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const FetchUserLeague = createAsyncThunk(
  `user/FetchUserLeague`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.post(
        getUserLeagues,
        payload.data,
        payload.headers,
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const ListOfInviteUsers = createAsyncThunk(
  `user/ListOfInviteUsers`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.post(
        listOfAcceptInviteUsers,
        payload.data,
        payload.headers,
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const ListUserLeaguePlayerQueue = createAsyncThunk(
  `user/ListUserLeaguePlayerQueue`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.post(
        listUserLeaguePlayerQueue,
        payload.data,
        payload.headers,
      );
      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const UserDetailsDelete = createAsyncThunk(
  `user/FetchUserDetailsDelete`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.get(
        deleteUserProfile + `/${payload}`,
      );
      // console.log("🚀 ~ file: userSlice.jsx:127 ~ responseDELETE:", response);

      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const UserDetailsEdit = createAsyncThunk(
  `user/UserDetailsEdit`,
  async (payload, thunkAPI) => {
    try {
      //console.log("-------------------->", payload);
      const response = await axiosPointLeagueAdmin.post(
        editUserProfile + `/${payload.userProfileId}`,
        payload.data,
        payload.headers,
      );
      // console.log("🚀 ~ file: userSlice.jsx:127 ~ responseDELETE:", response);

      return response.data;
    } catch (err) {
      toast.error(err?.response?.data?.detail || err?.message);
      return thunkAPI.rejectWithValue(
        err?.response?.data?.detail || err?.message,
      );
    }
  },
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetail: (state, action) => {
      //console.log("action", action);
      state.navigateUserDetail = action.payload;
    },
    setUserLeagueId: (state, action) => {
      //console.log("action", action);
      state.navigateLeagueId = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(FetchUsers.pending, state => {
      state.userLoading = true;
    });
    builder.addCase(FetchUsers.fulfilled, (state, action) => {
      console.log("action", action);
      const a = action?.payload?.data?.data?.rows;
      for (let i = 0; i < a.length; i++) {
        a[i]["index"] =
          action.meta.arg.data.page_record *
            (action?.payload?.data?.page_no - 1) +
          i +
          1;
      }
      state.userLoading = false;
      state.user = [...action?.payload?.data?.data?.rows];
      state.userCount = action?.payload?.data?.data?.count;
    });
    builder.addCase(FetchUsers.rejected, (state, action) => {
      state.userLoading = false;
    });
    builder.addCase(FetchUserDetails.pending, state => {
      state.fetchUserDetailsLoading = true;
    });
    builder.addCase(FetchUserDetails.fulfilled, (state, action) => {
      console.log("action", action);
      state.fetchUserDetailsLoading = false;
      state.fetchUser = action?.payload?.data?.userprofile;
    });
    builder.addCase(FetchUserDetails.rejected, (state, action) => {
      state.fetchUserDetailsLoading = false;
    });
    builder.addCase(FetchUserLeague.pending, state => {
      state.fetchUserLeagueLoading = true;
    });
    builder.addCase(FetchUserLeague.fulfilled, (state, action) => {
      state.fetchUserLeagueLoading = false;
      state.fetchUserLeague = action?.payload?.data?.leaguedata;
    });
    builder.addCase(FetchUserLeague.rejected, (state, action) => {
      state.fetchUserLeagueLoading = false;
    });
    builder.addCase(ListOfInviteUsers.pending, state => {
      state.inviteUsersLoading = true;
    });
    builder.addCase(ListOfInviteUsers.fulfilled, (state, action) => {
      // console.log(
      //   "🚀 ~ file: userSlice.jsx:211 ~ builder.addCase ~ action:",
      //   action
      // );
      state.inviteUsersLoading = false;
      state.leagueOwnerData = action?.payload?.data?.leagueOwnrData;
      state.inviteUserLeague = action?.payload?.data?.Data;
    });
    builder.addCase(ListOfInviteUsers.rejected, (state, action) => {
      state.inviteUsersLoading = false;
    });
    builder.addCase(ListUserLeaguePlayerQueue.pending, state => {
      state.listUserLeaguePlayerLoading = true;
    });
    builder.addCase(ListUserLeaguePlayerQueue.fulfilled, (state, action) => {
      state.listUserLeaguePlayerLoading = false;
      state.listUserLeaguePlayer = action?.payload?.data?.Data;
    });
    builder.addCase(ListUserLeaguePlayerQueue.rejected, (state, action) => {
      state.listUserLeaguePlayerLoading = false;
    });
    builder.addCase(UserDetailsEdit.pending, state => {
      state.editUserLoading = true;
    });
    builder.addCase(UserDetailsEdit.fulfilled, (state, action) => {
      // console.log(
      //   "🚀 ~ file: userSlice.jsx:174 ~ builder.addCase ~ action:",
      //   action
      // );
      state.editUserLoading = false;
    });
    builder.addCase(UserDetailsEdit.rejected, (state, action) => {
      state.editUserLoading = false;
    });
  },
});

export const { setUserDetail, setUserLeagueId } = userSlice.actions;
export default userSlice.reducer;
