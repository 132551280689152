import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname.split("/");

  const fullArray = [
    ["pages", "home"], // HOME ARRAY
    ["pages", "user-master", "user-profile", "teams-details"], // USER MASTER ARRAY
    ["pages", "player-master", "player-details", "state"], // PLAYER MASTER ARRAY
    ["pages", "system-page"], // SYSTEM PAGES ARRAY
  ];

  function findArrayContainingValue(arr, value) {
    return arr.find(subArr => subArr.includes(value));
  }

  const result = pathName.slice(0, 2).join("");
  const resultArray = findArrayContainingValue(fullArray, result);
  const newA = resultArray.slice(0, pathName.length);

  // console.log("newA", newA);
  const handelClickRoutes = index => {
    pathName.map((item, i) => {
      if (i === index) {
        const numberArray = [...Array(index + 1).keys()];
        // console.log(numberArray);
        const route = numberArray.map(index => pathName[index]).join("/");
        // console.log("item", route);
        navigate(route);
      }
    });
  };

  return (
    <div className="d-flex flex-wrap">
      {newA?.map((items, index) => {
        return (
          <div
            onClick={() => {
              handelClickRoutes(index);
            }}
            key={index}
          >
            {/* {items === "" ? "Pages" : items} */}
            {items}
            <span className="mx-1"> {newA?.length - 1 > index && "/"} </span>
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
