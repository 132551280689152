const routesConstants = {
  LOGIN: "login",
  REGISTRATION: "registration",
  FORGOT_PASSWORD: "forgot-password",
  HOME_PAGE: "home",
  PLAYER_MASTER_PAGE: "player-master",
  USER_MASTER_PAGE: "user-master",
  state: ":playermasterid/state",
  SYSTEM_PAGES: "system-page",
};

export default routesConstants;
