export const LOGIN = "/login";
export const USER_PATTEN = "/v1";
export const USER = "/list-user";
export const PLAYERPROFILE = "/listNbaPlayers";
export const PlayerProfileDetailsUrl = "/getNbaPlayerDetail";
export const PlayerScore = "/getNbaPlayerscore";
export const getUser = "/listUsers";
export const listUserProfile = "/listuserprofile";
export const teamLogo = "/listteams";
export const getPlayerNextGame = "/getplayerNextGame";
export const getUserLeagues = "/AdmingetUserLeagues";
export const listOfAcceptInviteUsers = "/listOfAcceptInviteUsers";
export const listUserLeaguePlayerQueue = "/listLeagueUserPlayerDraft";
export const deleteUserProfile = "/deleteUserProfile";
export const editUserProfile = "/editUserProfile";
export const createAndEditPages = "/createAndEditPages";
export const ListAllPage = "/ListAllPage";
export const deletePolicyPage = "/deletePage";
export const PolicyListPage = "/ListPage";
export const GetUsersCount = "/getUsersCount";
export const ListNcaamPlayers = "/listNcaamPlayers";
export const GetNcaamPlayerscore = "/getNcaamPlayerscore";
export const GetNcaamPlayerDetail = "/getNcaamPlayerDetail";
