import React from "react";
import styles from "./index.module.css";
import classNames from "classnames";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { menuItems } from "./constant";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "modules/login/features/loginSlice";
import Cookies from "services/cookies";
import { GiHamburgerMenu } from "react-icons/gi";
import { toggleSidebar } from "./slice/layoutSlice";
import { MdClose } from "react-icons/md";
import Breadcrumb from "components/Breadcrumb";

const Header = () => {
  const location = useLocation();

  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)",
  );

  const dispatch = useDispatch();
  const handleLogout = () => {
    Cookies.clear();
    dispatch(logout());
  };

  const pathName = location.pathname.split("/");
  const result = pathName.slice(0, 2).join("/");
  //console.log(pathName);

  const FilterPageName = menuItems.find(items => `/${items.routes}` === result);

  //console.log("FilterPageName", FilterPageName);
  const { isOpen } = useSelector(state => state.layout);

  const handelChange = () => {
    dispatch(toggleSidebar());
  };
  const navigate = useNavigate();
  //const Breadcrumb = ["Pages", "player-master", "player-details", "state"];

  return (
    <div
      className={classNames(
        styles.HeaderBar,
        "d-flex justify-content-between align-items-center my-2 mx-2",
      )}
    >
      <div className={classNames(styles.pageName)}>
        <div>
          <span className={classNames(styles.pageContain, "d-flex flex-wrap")}>
            {/* Pages / {FilterPageName.menuName} */}
            <Breadcrumb />
            {/* <Breadcrumb /> */}
          </span>
          <div>
            <h3 className={classNames(styles.h3PageName)}>
              {FilterPageName.menuName}
            </h3>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          styles.menuItems,
          "d-flex justify-content-start align-items-center gap-3",
        )}
      >
        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: "pointer" }}
              color="white"
              name="Adela Parkson"
              bg="#11047A"
              size="sm"
              w="40px"
              h="40px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋&nbsp; Hey, Admin
              </Text>
            </Flex>
            <Flex flexDirection="column" pl="10px">
              <MenuItem
                //_hover={{ bg: "none" }}
                //_focus={{ bg: "none" }}
                color="red.400"
                borderRadius="8px"
                px="14px"
                onClick={handleLogout}
              >
                <Text fontSize="sm">Log out</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>{" "}
        {isOpen ? (
          <GiHamburgerMenu
            size={25}
            className={classNames(styles.HamburgerMenu)}
            onClick={handelChange}
          />
        ) : (
          <MdClose
            size={25}
            className={classNames(styles.HamburgerMenu)}
            onClick={handelChange}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
