import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: true,
};

export const layoutSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Define the reducer function to toggle isOpen
    toggleSidebar: state => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleSidebar } = layoutSlice.actions;
export default layoutSlice.reducer;
