import React from "react";
import ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import App from "App";
import theme from "theme/theme";
import "assets/css/index.scss";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
ReactDOMClient.createRoot(document.querySelector("#root")).render(
  <ChakraProvider theme={theme}>
    <ToastContainer />
    <BrowserRouter>
      <ThemeEditorProvider>
        <App />
      </ThemeEditorProvider>
    </BrowserRouter>
  </ChakraProvider>,
);
