import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetUsersCount } from "constants/url";
import { axiosPointLeagueAdmin } from "services/api";

const initialState = {
  usersCount: [],
  nbaCount: "",
  ncaaCount: "",
  totalUser: "",
  isUsersCountLoading: true,
};

export const getUsersCount = createAsyncThunk(
  `authentication/getUsersCount`,
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPointLeagueAdmin.get(GetUsersCount, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(
        err?.response?.data?.non_field_errors?.at(0),
      );
    }
  },
);

export const homeSlice = createSlice({
  name: "home",
  initialState,
  extraReducers: builder => {
    builder.addCase(getUsersCount.pending, state => {
      state.isUsersCountLoading = true;
    });
    builder.addCase(getUsersCount.fulfilled, (state, action) => {
      state.isUsersCountLoading = false;

      const sortUserCountData = action?.payload?.data?.count?.sort(
        (a, b) => a["year"] - b["year"],
      );

      state.usersCount = [...sortUserCountData];
      state.nbaCount = action?.payload?.data?.totalNbaCount;
      state.ncaaCount = action?.payload?.data?.totalNcaaCount;
      state.totalUser = action?.payload?.data?.totalUserCount;
    });
    builder.addCase(getUsersCount.rejected, state => {
      state.isUsersCountLoading = false;
    });
  },
});

export default homeSlice.reducer;
